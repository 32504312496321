<template>
  <div class="lg:container mx-auto grid grid-cols-12 my-2 lg:my-0 lg:gap-12 xl:gap-24 relative z-10 items-start lg:items-center">
    <div
      data-aos="fade-in"
      class="col-span-12 mb-8 md:mb-0 md:col-span-6 flex items-center h-72 md:h-full lg:h-72 xl:h-100 order-last lg:order-last px-4 md:px-0"
    >
      <div
        v-if="(typeof quote.image !== 'undefined')"
        class="transition-all lazy shadow-md group-hover:shadow-2xl h-full w-full rounded-md background"
        style="flex: 0 0 auto;"
        :data-bg="getImageSrc(viewItem, 'desktop')"
        :data-bg-hidpi="getImageSrc()"
      />
    </div>
    <article
      data-aos="fade-right"
      class="post-card p-4 lg:p-8 lg:shadow-lg md:text-left col-span-12 md:col-span-6 order-first lg:order-first bg-white text-dark   overlap--tile left--tile min-h-24 z-20"
    >
      <a
        :href="'https://storysh.de/zitate/' + quote.slug"
        :title="quote.quote"
      >
        <h2 class="text-dark transition-colors mb-4 lg:mb-8 text-left px-3 md:px-0">
          {{ $t('QUOTE_TEASER_HEADLINE') }}
        </h2>
        <p
          v-if="(typeof quote.quote !== 'undefined')"
          class="text-dark transition-colors font-medium text-lg mb-2 text-left px-3 md:px-0"
        >
          {{ quote.quote }}
        </p>
        <p
          v-if="(typeof quote.quote !== 'undefined')"
          class="text-dark transition-colors text-sm mb-8 text-left px-3 md:px-0"
        >
          &copy; {{ quote.information }}
        </p>
      </a>
    </article>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "CurrentQuote",
  components: {
  },
  inject: ['dataset'],
  data() {
    return {
      quote: {}
    }
  },
  created() {
    this.getQuote();
  },
  methods: {
    getQuote() {
      axios.get(process.env.apiURL + '/fe/api/quote/current')
        .then(response =>  {
          this.quote = response.data;
        })
        .catch(function (error) {
        })
        .finally(response =>  {
        });
    },
    getImageSrc() {
      return this.quote.image;
    }
  }
};
</script>
